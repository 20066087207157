import React from 'react'

import Layout from '../components/body/layout';
import Head from '../components/header/head';

import mainText from './index.module.scss';


const Aboutpage = () => {
    return (
        <Layout>
            <Head title="About" />
            <div className={mainText.text}>
                <h1>About Me</h1>
                <p>This is how it all started</p>
            </div>
            
        </Layout>
    )
}

export default Aboutpage;